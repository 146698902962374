import React from 'react';
import { 
  Drawer, DrawerBody, DrawerOverlay, DrawerContent, 
  useBreakpointValue, VisuallyHidden, Link, Text,
  Flex, IconButton 
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

const MailDrawer = ({ isOpen, onClose }) => {
  const openDirection = useBreakpointValue({ base: 'left', lg: 'right' });
  const PRIMARY_COLOR = '#e84f1c';
  const paddingBottom = useBreakpointValue({base: '50', lg: '0'})

  return (
    <Drawer isOpen={isOpen} placement={openDirection} onClose={onClose} size={{ base: "full", md: "md" }}>
      <DrawerOverlay />
      <DrawerContent bg={"#F9F9F9"} fontFamily={"Deserta"} paddingBottom={paddingBottom}>
        <Flex justifyContent="flex-end" p={4}>
          <IconButton
            icon={<CloseIcon />}
            onClick={onClose}
            aria-label="Close drawer"
            variant="ghost"
            colorScheme="gray"
          />
        </Flex>
        <DrawerBody>
          <Flex direction="column" justifyContent="center" alignItems="center" height="100%" fontSize="xl">
            <Text mb="2">Call us:</Text>
            <Link href="tel:+1234567890" aria-label="Phone" color={PRIMARY_COLOR} fontSize="2xl">
              <VisuallyHidden>Phone</VisuallyHidden>
              +46 70 170 10 44
            </Link>
            <Text mt="2">or send us an Email:</Text>
            <Link href="mailto:info@nordiv.se" aria-label="Email" color={PRIMARY_COLOR} fontSize="2xl">
              <VisuallyHidden>Email</VisuallyHidden>
              info@nordiv.se
            </Link>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default MailDrawer;
