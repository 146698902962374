import React, { useState } from "react";
import { Box, Container , VStack, Heading, useBreakpointValue } from "@chakra-ui/react";
import CardLinks from "../Components/CardLinks";
import { Divider } from "@chakra-ui/react";
import desktopVideo from '../includes/video/LandingPageDesktopV8.mp4';
import mobileVideo from '../includes/video/LandingPageMobileV8.mp4';
import startFrameDesktop from '../includes/video/StartFrameDesktopVersion.webp'
import startFrameMobile from '../includes/video/StartFrameMobileLanding.webp'
import FadeOutBox from "../Components/FadeOutBox";

export default function Home() {
  const [videoError, setVideoError] = useState(false);
  const videoSource = useBreakpointValue({ base: mobileVideo, lg: desktopVideo });
  const startFrame = useBreakpointValue({ base: startFrameMobile, lg: startFrameDesktop})

  return (
    <>
      <Box
        id="home-sec"
        position="relative"
        h="100vh"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        bgImage={videoError ? `url(${startFrame})` : ``}
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="cover"
        transition="background 2s ease-in-out"
        zIndex={0}
      >
        <video
          key={videoSource}
          autoPlay="autoplay"
          muted
          loop
          onError={() => setVideoError(true)}
          playsInline
          poster={startFrame}
          style={{
            position: "absolute",
            width: "100vw",
            left: "50%",
            top: "50%",
            height: "100vh",
            objectFit: "cover",
            transform: "translate(-50%, -50%)",
            zIndex: "-1"
          }}
        >
          <source src={videoSource} type="video/mp4" />
        </video>
        <FadeOutBox duration={7000}>
          <Heading color={'#f9f9f9'} size={'lg'} fontFamily={'deserta'}>
            "Vision unveils the unseen, <br/>
            revealing paths hidden to others." <br/>
            Nordiv AB
          </Heading>
        </FadeOutBox>
        <Container centerContent>
        <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            bgColor="rgba(0, 0, 0, 0.2)"
            zIndex={-1}
          />
          <Box
            position="absolute"
            bottom="20px"
            left="50%"
            transform="translateX(-50%)"
            fontSize="24px"
            color="white"
            cursor="pointer"
            animation="bounce 2s infinite"
            zIndex={2}
          >
        &#x21CA; {/* Double down arrow */}
      </Box>
    </Container>
    </Box>
    <Box bg={'#F7F2F8'} d="flex" flexDirection="column" alignItems="center" justifyContent="center" pl={"10vw"} pr={"10vw"} pt={"10vh"} pb={"10vh"}>
        <VStack spacing={2}>
          <Heading size={'2xl'} fontFamily="Deserta">
            Services
          </Heading>
        </VStack>
        <Divider borderColor="#e84f1c" mb={4} mt={"10px"} />
        <CardLinks />
      </Box>
    </>
  );
}