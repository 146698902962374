import React, { useState, useEffect } from 'react';
import './App.css';
import Navbar from './Components/Navbar';
import Home from './Pages/Home';
import LightArtistry from './Pages/Event';
import GraphicDesign from './Pages/Digital';
import Footer from './Components/Footer';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import MailDrawer from './Components/MailDrawer';
import SustainableSpaces from './Pages/Spaces';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerClose = () => setDrawerOpen(false);
  const handleDrawerOpen = () => setDrawerOpen(true);

  return (
    <ChakraProvider>
      <Router>
        <ScrollToTop />
        <Navbar onMailDrawerOpen={() => setDrawerOpen(true)}/>
        <MailDrawer isOpen={isDrawerOpen} onClose={handleDrawerClose} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/light" element={<LightArtistry />} />
          <Route path="/graphic" element={<GraphicDesign />} />
          <Route path="/spaces" element={<SustainableSpaces />} />
        </Routes>
        <Footer />
      </Router>
    </ChakraProvider>
  );
}

export default App;