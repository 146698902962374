import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Flex,
  Text,
  Stack,
  Link,
  Button,
  useColorModeValue,
  Spacer,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Container,
  Image,
  Center
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import logoWhite from '../includes/images/NordIv loggo inverted.png';
import logoBlack from '../includes/images/NordIv loggo full alpha.png';
import { useLocation } from 'react-router-dom';

function Navbar({ onMailDrawerOpen }) {
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [scrollPosition, setScrollPosition] = useState(0);

  const SCROLL_THRESHHOLD = window.innerHeight - 100;
  const HOVER_COLOR = '#ffffff';
  const HOVER_COLOR_TEXT = '#e84f1c'
  const PRIMARY_COLOR = '#e84f1c';
  const LINK_COLOR = '#e84f1c'

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const bgColor = scrollPosition > SCROLL_THRESHHOLD ? 'rgba(17, 17, 18, 1)' : 'rgba(17, 17, 18, 0.5)';
  const bgColorSolid = 'rgba(17, 17, 18, 1)';

  return (
    <Container centerContent fontFamily={"Deserta"}>
      <Flex
        as="nav"
        align="center"
        justify="space-between"
        wrap="nowrap"
        padding="1.5rem"
        width="100%"
        position={'fixed'}
        top={0}
        zIndex="10"
        bg={useColorModeValue(isHomePage ? bgColor : bgColorSolid, isHomePage ? bgColor : bgColorSolid)}
        color={useColorModeValue('gray.600', 'white')}
        transition="background-color 0.1s"
        minWidth={'100vw'}
        minHeight={'100px'}
      >
      {/* Logo and Title */}
      <Center>
      <RouterLink to="/">
        <Image transition={'all .1s ease'} 
          src={logoWhite}
          alt='Nordiv Logo' 
          width='auto' 
          maxH={{base: "2rem", lg: "3rem"}}
          mt={{base: -3, lg: -4}}
          ml={{base: 0, lg: 160}}
          mr={{base: 0, lg: 5}}
        />
        </RouterLink>
      </Center>
      <Spacer />
    {/* Navbar Links */}
    <IconButton
      display={{ base: 'flex', lg: 'none' }}
      aria-label="Open menu"
      fontSize="20px"
      color={scrollPosition > SCROLL_THRESHHOLD ? 'white' : 'white'}
      variant="ghost"
      icon={<HamburgerIcon />}
      onClick={onOpen}
    />
    <Stack
      spacing={4}
      direction={{ base: 'column', lg: 'row' }}
      display={{ base: 'none', lg: 'flex' }}
      width={{ base: 'full', lg: 'auto' }}
      alignItems="center"
      flexGrow={1}
      mt={{ base: 4, lg: 0 }}
      whiteSpace={{ base: 'normal', lg: 'nowrap' }}
    >
      <RouterLink to="/light" style={{
        color: scrollPosition > SCROLL_THRESHHOLD ? PRIMARY_COLOR : LINK_COLOR
      }}>
        <Box p={2} borderRadius="lg" _hover={{ bg: HOVER_COLOR }}>
          <Text fontSize="lg" color={'#e84f1c'}>
            Light Artistry
          </Text>
        </Box>
      </RouterLink>
      <RouterLink to="/graphic" style={{
        color: scrollPosition > SCROLL_THRESHHOLD ? PRIMARY_COLOR : LINK_COLOR
      }}>
        <Box p={2} borderRadius="lg" _hover={{ bg: HOVER_COLOR }}>
          <Text fontSize="lg" color={'#e84f1c'}>
            Graphic Design
          </Text>
        </Box>
      </RouterLink>

      <RouterLink to="/spaces" style={{
        color: scrollPosition > SCROLL_THRESHHOLD ? PRIMARY_COLOR : LINK_COLOR
      }}>
        <Box p={2} borderRadius="lg" _hover={{ bg: HOVER_COLOR }}>
          <Text fontSize="lg" color={'#e84f1c'}>
            Sustainable Spaces
          </Text>
        </Box>
      </RouterLink>
    </Stack>
    <Drawer placement="right" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent bg='#F9F9F9' fontFamily={"Deserta"}>
        <DrawerCloseButton />
        <DrawerHeader>
          <RouterLink to="/" onClick={onClose}>
            <Image transition={'all .3s ease'} 
          src={logoBlack} alt='Nordiv Logo' width='auto' 
          maxH={{base: "2rem", lg: "3rem"}} />
          </RouterLink>
        </DrawerHeader>
        <DrawerBody>
          <Stack
            spacing={4}
            as={Stack}
            direction="column"
            display="flex"
            width="full"
            alignItems="center"
            flexGrow={1}
            mt={4}
          >
            <Link as={RouterLink} to={"/light"} onClick={onClose}>Light Artistry</Link>
            <Link as={RouterLink} to={"/graphic"} onClick={onClose}>Graphic Design</Link>
            <Link as={RouterLink} to={"/spaces"} onClick={onClose}>Sustainable Spaces</Link>
            <Button color="white" backgroundColor={PRIMARY_COLOR} _hover={{ backgroundColor: HOVER_COLOR, color: "white" }}
      mr={{base: 0, lg: 160}} onClick={onMailDrawerOpen}
    >Contact</Button>
            {/*            <Box mt={10} textAlign={'center'}>
              <Link as="a" href="tel:+46701701044" color={PRIMARY_COLOR}>
              Call us: <br/>
              +46 70 170 10 44
              </Link>
            </Box> */}
          </Stack>
          
        </DrawerBody>
      </DrawerContent>
    </Drawer>
    {/* Other Navbar Items */}
    <Box display={{ base: 'none', lg: 'block' }} justifyContent='center'>
    <Button color="white" backgroundColor={PRIMARY_COLOR} _hover={{ backgroundColor: HOVER_COLOR, color: HOVER_COLOR_TEXT, borderColor: HOVER_COLOR_TEXT }}
      mr={{base: 0, lg: 160}} onClick={onMailDrawerOpen}
    >Contact</Button>
    </Box>
        </Flex>
    </Container>
  );
}

export default Navbar;