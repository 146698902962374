import { Box, Heading, Image, Text, useBreakpointValue } from '@chakra-ui/react';
import { useState } from 'react';
import Section from '../Components/Section';
import videoSourceDesktop from '../includes/images/spaces/SustainableSpacesDesktop15bitShortV2.mp4'
import videoSourceMobile from '../includes/images/spaces/SustainableSpacesVideoV2Mobile15bitShort.mp4'
import backupImageDesktop from '../includes/images/spaces/SustainableStartFrameDesktopVersion2.webp';
import backupImageMobile from '../includes/images/spaces/SustainableStartFrameMobile.webp';
import spaces1 from '../includes/images/spaces/Sustain2_1080.webp'
import spaces2 from '../includes/images/spaces/Sustain3_1080.webp'
import spaces3 from '../includes/images/spaces/Sustain4_1080.webp'
import spaces4 from '../includes/images/spaces/Sustain5_1080.webp'

function SustainableSpaces() {
  const [videoError, setVideoError] = useState(false);
  const maxWValue = useBreakpointValue({ base: '100vw', lg: '37vw'})
  const borderRadius = useBreakpointValue({ base: '0', lg: '10px'})
  const textAlignCenter = 'center'
  const textAlignLeft = 'left'
  const wrap = useBreakpointValue({base: 'wrap', lg: 'nowrap'})
  const bottomTitlePosition = useBreakpointValue({base: 100, lg: 10})
  const fontStyleItalic = 'italic'
  const fontSize = 'sm'
  const headingSize = 'md'
  const videoSource = useBreakpointValue({base: videoSourceMobile, lg: videoSourceDesktop})
  const leftTitlePosition = useBreakpointValue({base: 5, lg: 200})
  const textBorder = "-1px -1px 0 #000,1px -1px 0 #000,-1px 1px 0 #000,1px 1px 0 #000"
  const backupImage = useBreakpointValue({base: backupImageMobile, lg: backupImageDesktop})
  const collection = [
    <div>
      <Heading textAlign={textAlignCenter} size={headingSize}>
      Crafting Sustainable Futures.
      </Heading>
      <Text textAlign={textAlignLeft} fontSize={fontSize}>
      At Nordiv AB, we blend technical precision with sustainable design, creating spaces that mirror reality with unparalleled accuracy. Our expertise ranges from digital installations to material specifics, ensuring comprehensive project execution. Through meaningful dialogue with clients, we ensure each design reflects their vision, fostering environments that encourage exploration and interaction.
      </Text>
    </div>,
    [<Text  fontStyle={fontStyleItalic}>
        High-tech retail space designed for independent discovery and immersive shopping experiences.
    </Text>,
    <Image src={spaces1} borderRadius={borderRadius} maxW={maxWValue}/>],
    <div>
     <Heading textAlign={textAlignCenter} size={headingSize}>
     Tailored for You.
     </Heading>
     <Text textAlign={textAlignLeft} fontSize={fontSize}>
     Our process transforms your unique visions into bespoke realities. From initial concept to final reveal, we craft spaces that are as individual as our clients, integrating eco-efficient solutions for a smarter living.
     </Text>
    </div>,
   [<Image src={spaces2} borderRadius={borderRadius} maxW={maxWValue}/>,
   <Text fontStyle={fontStyleItalic} >
     Villa designed for superior environmental efficiency, with advanced water, energy, and utility management.
   </Text>],
    <div>
    <Heading textAlign={textAlignCenter} size={headingSize}>
    Partnership and Process.
    </Heading>
    <Text textAlign={textAlignLeft} fontSize={fontSize}>
    Collaboration is the cornerstone of our design philosophy. Together, we create spaces that align with your priorities, exceeding expectations through a partnership built on understanding and implementation.
    </Text>
   </div>,
    [<Text fontStyle={fontStyleItalic}>
    Custom-designed LED lighting fixture for replacing older venue lighting from 1935.
    </Text>,
    <Image src={spaces3} borderRadius={borderRadius} maxW={maxWValue}/>],
    <div>
     <Heading textAlign={textAlignCenter} size={headingSize}>
     Visionary Spaces.
     </Heading>
     <Text textAlign={textAlignLeft} mb={0} fontSize={fontSize}>
     Our commitment is to transform your dreams into tangible experiences. By prioritizing your desires, we craft spaces that not only function beautifully but resonate deeply with those who inhabit them.
     </Text>
    </div>,
    [<Image src={spaces4} borderRadius={borderRadius} maxW={maxWValue}/>,
    <Text fontStyle={fontStyleItalic} >
     Long lasting eco-friendly charred panel installation showcasing low maintenance and aesthetic appeal.
    </Text>],
    <div>
     <Heading textAlign={textAlignCenter} size={headingSize}>
     Uniquely Yours.
     </Heading>
     <Text textAlign={textAlignLeft} mb={0} fontSize={fontSize}>
     Embark on a design journey with Nordiv AB, where your vision is the inspiration for our innovation. Together, let's create spaces that stand as a testament to your individuality and our dedication to sustainable excellence.
     </Text>
    </div>  
  ];
  return (
    <Box
      bg="#efefef"
      d="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      position="relative"
    >
      <Box position="relative" minW={'100vw'} h={'100vh'}>
        <Box as='video' 
        key={videoSource}
              autoPlay="autoplay"
              muted
              loop
              playsInline
              onError={() => setVideoError(true)}
              poster={backupImage}
              position={'relative'}
              minW={'100vw'}
              h={'100vh'}
              objectFit={'cover'}
        >
          <source src={videoSource} type="video/mp4" codecs="avc1.4d002a" />
        </Box>
        <Heading
        size={'xl'}
        left={leftTitlePosition}
        bottom={bottomTitlePosition}
        color={'white'} 
        position={'absolute'} 
        whiteSpace={wrap}
        textShadow={textBorder}
        fontFamily={'Deserta'}
        >
          Sustainable Spaces
        </Heading>
      </Box>
      <Box zIndex={1} tex>
        <Section
          content={collection}
        />
      </Box>
    </Box>
  );
}

export default SustainableSpaces;