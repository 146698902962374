import { Box, Heading, Image, Text, useBreakpointValue } from '@chakra-ui/react';
import { useState } from 'react';
import Section from '../Components/Section';
import videoSourceDesktop from '../includes/images/graphic/GraphicDesignMainDesktop15bit_Version2.mp4'
import videoSourceMobile from '../includes/images/graphic/GraphicDesignMainMobile15bit_Version2.mp4'
import astronautDesktop from '../includes/images/graphic/Astronaut1.mp4'
import backupImage from '../includes/images/graphic/GraphicDesignVideoFirstFrameDesktop.webp'
import graphic1 from '../includes/images/graphic/Graphic1.webp'
import graphic2 from '../includes/images/graphic/Graphic4_1080.webp'
import graphic4 from '../includes/images/graphic/Graphic5_1080.webp'

function GraphicDesign() {
  const [_, setVideoError] = useState(false);
  const maxWValue = useBreakpointValue({ base: '100vw', lg: '37vw'})
  const minWValue = useBreakpointValue({ base: '100vw', lg: '37vw'})
  const borderRadius = useBreakpointValue({ base: '0', lg: '10px'})
  const textAlignCenter = 'center'
  const textAlignLeft = 'left'
  const wrap = useBreakpointValue({base: 'wrap', lg: 'nowrap'})
  const bottomTitlePosition = useBreakpointValue({base: 100, lg: 10})
  const leftTitlePosition = useBreakpointValue({base: 5, lg: 200})
  const fontStyleItalic = 'italic'
  const fontSize = 'sm'
  const headingSize = 'md'
  const videoSource = useBreakpointValue({base: videoSourceMobile, lg: videoSourceDesktop})
  const textBorder = "-1px -1px 0 #000,1px -1px 0 #000,-1px 1px 0 #000,1px 1px 0 #000"
  const collection = [
    <div>
      <Heading textAlign={textAlignCenter} size={headingSize}>
      Your Story, Visually Told.
      </Heading>
      <Text textAlign={textAlignLeft} fontSize={fontSize}>
      At Nordiv AB, where detail meets design, we turn complex ideas into lifelike visuals for site-specific projects, driven by our passion for precision. As pioneers in graphic design and digital media, we elevate your brand with innovative digital content and engaging graphics, uniquely crafted for you.
      </Text>
    </div>,
    [<Text fontStyle={fontStyleItalic} fontSize={fontSize}>
        Visualization of the Volvo Environment Prize 2023, aligning and promoting studio vision.
    </Text>,
    <Image src={graphic1} borderRadius={borderRadius} maxW={maxWValue}/>],
    <div>
     <Heading textAlign={textAlignCenter} size={headingSize}>
     Innovation Meets Technology.
     </Heading>
     <Text textAlign={textAlignLeft} fontSize={fontSize}>
     With cutting-edge tools like Unreal Engine, we craft immersive, dynamic media to showcase your brand, offering realistic renderings and animations that captivate.
     </Text>
    </div>,
   [<Image src={graphic2} borderRadius={borderRadius} maxW={maxWValue}/>,
   <Text fontStyle={fontStyleItalic} fontSize={fontSize}>
    Winning concept for a digital game museum pitch.
   </Text>],
    <div>
    <Heading textAlign={textAlignCenter} size={headingSize}>
    Tailored Graphics Across All Touchpoints.
    </Heading>
    <Text textAlign={textAlignLeft} fontSize={fontSize}>
    We design memorable graphics for every brand interaction, from welcoming website visuals to impactful brochures and presentations, ensuring consistency and distinction.
    </Text>
   </div>,
    [<Text fontStyle={fontStyleItalic} fontSize={fontSize}>
    Custom media for multi-screen for the event “Cosmic Cube” 2024.
    </Text>,
    <Box
      borderRadius={borderRadius}
      maxW={maxWValue}
      minW={minWValue}
      overflow={'hidden'}
    >
    <video
      minW={'100vw'}
      key={astronautDesktop}
      muted
      autoPlay='autoplay'
      playsInline
      loop
      onError={() => setVideoError(true)}>
      <source src={astronautDesktop} type='video/mp4'  codecs="avc1.4d002a"/>
    </video>
    </Box>],
    <div>
     <Heading textAlign={textAlignCenter} size={headingSize}>
     Collaborative Creation.
     </Heading>
     <Text textAlign={textAlignLeft} mb={0} fontSize={fontSize}>
     Our collaborative approach involves you at every step, guaranteeing designs that exceed expectations and authentically reflect your brand.
     </Text>
    </div>,
    [<Image src={graphic4} borderRadius={borderRadius} maxW={maxWValue}/>,
    <Text fontStyle={fontStyleItalic} fontSize={fontSize}>
     Custom made Pokémon for marketing.
    </Text>],
    <div>
     <Heading textAlign={textAlignCenter} size={headingSize}>
     Your Vision, Our Mastery.
     </Heading>
     <Text textAlign={textAlignLeft} mb={0} fontSize={fontSize}>
     Explore the frontier of graphic design with Nordiv AB. Beyond creation, we innovate and redefine visual communication. Let's bring your story to life in the most engaging way. Contact us to elevate your visual identity.
     </Text>
    </div>  
  ];
  return (
    <Box
      bg="#fff9f7"
      d="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      position="relative"
    >
      <Box position="relative" minW={'100vw'} h={'100vh'}>
        <Box as='video'
          key={videoSource}
          muted
          autoPlay='autoplay'
          playsInline
          loop
          onError={() => setVideoError(true)}
          poster={backupImage}
          position={'absolute'}
          minW={'100vw'}
          h={'100vh'}
          objectFit={'cover'}
        >
          <source src={videoSource} type="video/mp4" codecs="avc1.4d002a" />
        </Box>
        <Heading
        size={'xl'}
        left={leftTitlePosition}
        bottom={bottomTitlePosition}
        color={'white'} 
        position={'absolute'} 
        whiteSpace={wrap}
        textShadow={textBorder}
        fontFamily={'Deserta'}
        >
          Graphic Design
        </Heading>
      </Box>
      <Box zIndex={1} tex>
        <Section
          content={collection}
        />
      </Box>
    </Box>
  );
}

export default GraphicDesign;
