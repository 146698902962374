import { Box, Heading, Image, Text, useBreakpointValue, Skeleton } from '@chakra-ui/react';
import React, { useState } from 'react';
import Section from '../Components/Section';
import lightOpeningDesktop from '../includes/images/light/Light5.webp'
import lightOpeningMobile from '../includes/images/light/LightArtestry_Main2.webp'
import light1 from '../includes/images/light/Light1.webp'
import light2 from '../includes/images/light/Light2.webp'
import light3 from '../includes/images/light/Light3.webp'
import light4 from '../includes/images/light/Light4.webp'
import light5 from '../includes/images/light/PlansOnDesk_1080.webp'

function LightArtistry() {
  const [imgLoaded, setImgLoaded] = useState(false);
  const maxWValue = useBreakpointValue({ base: '100vw', lg: '37vw'})
  const paddingTop = useBreakpointValue({base: '20px', lg: '0'})
  const borderRadius = useBreakpointValue({ base: '0', lg: '10px'})
  const textAlignCenter = 'center'
  const textAlignLeft = 'left'
  const wrap = useBreakpointValue({base: 'wrap', lg: 'nowrap'})
  const bottomTitlePosition = useBreakpointValue({base: 100, lg: 10})
  const leftTitlePosition = useBreakpointValue({base: 5, lg: 200})
  const textBorder = "-1px -1px 0 #000,1px -1px 0 #000,-1px 1px 0 #000,1px 1px 0 #000"
  const fontStyleItalic = 'italic'
  const fontSize = 'sm'
  const headingSize = 'md'
  const lightOpening = useBreakpointValue({base: lightOpeningMobile, lg: lightOpeningDesktop})
  
  const collection = [
    <div>
      <Heading textAlign={textAlignCenter} size={headingSize}>
      Light Artistry:<br/>
      Collaborative and Personalized.
      </Heading>
      <Text textAlign={textAlignLeft} fontSize={fontSize} pt={paddingTop}>
      At Nordiv AB, "Light Artistry" isn't just a service—it's a partnership. We dive deep into your vision, crafting lighting and stage designs that mirror your unique dreams and goals.
      </Text>
    </div>,
    [<Text fontStyle={fontStyleItalic} pt={paddingTop}>
        Icona Pop live at U-port Festival 2023. Nordiv was responsible for the function and flawless operation of the lighting system on main stage.
    </Text>,
    <Image src={light1} borderRadius={borderRadius} maxW={maxWValue}/>],
    <div>
     <Heading textAlign={textAlignCenter} size={headingSize}>
     Custom Lighting for Every Dream.
     </Heading>
     <Text textAlign={textAlignLeft} fontSize={fontSize}>
     Our dedication shines through our bespoke lighting solutions, tailored to fit each project's unique vision. From the drawing board to the final execution, we're committed to excellence.
     </Text>
    </div>,
   [<Image src={light2} borderRadius={borderRadius} maxW={maxWValue}/>,
   <Text fontStyle={fontStyleItalic} pt={paddingTop}>
    A special rigging solution to accommodate more hoist points than the venue could deliver for the show "Life Reflected".
   </Text>],
    <div>
    <Heading textAlign={textAlignCenter} size={headingSize}>
    A Symphony of Light,<br/>
    Created Together.
    </Heading>
    <Text textAlign={textAlignLeft} fontSize={fontSize}>
    Our work is a collaborative symphony of light, enhancing spaces and resonating with audiences. Together, we turn your vision into reality, exceeding all expectations.
    </Text>
   </div>,
    [<Text fontStyle={fontStyleItalic} pt={paddingTop}>
    "This Woman's Work: Kate Bush Tribute," 2018. Lighting, stage and video design by Anton Nord.
    </Text>,
    <Image src={light3} borderRadius={borderRadius} maxW={maxWValue}/>],
    <div>
     <Heading textAlign={textAlignCenter} size={headingSize}>
     Beyond Lighting:<br/>
     Crafting Emotional Atmospheres.
     </Heading>
     <Text textAlign={textAlignLeft} mb={0} fontSize={fontSize}>
     Nordiv AB goes beyond lighting to create atmospheres that tell a story and evoke emotions, ensuring each installation leaves a lasting impression.
     </Text>
    </div>,
    [<Image src={light4} borderRadius={borderRadius} maxW={maxWValue}/>,
    <Text fontStyle={fontStyleItalic} pt={paddingTop}>
     Corporate event lighting and rigging by Nordiv.
    </Text>],
    <div>
     <Heading textAlign={textAlignCenter} size={headingSize}>
     Illuminating Your Vision.
     </Heading>
     <Text textAlign={textAlignLeft} mb={0} fontSize={fontSize}>
     Join us in a journey of light where your ideas illuminate our creativity. Nordiv AB is dedicated to bringing your visions to light, defining spaces and moments.
     </Text>
    </div>,
    [<Text fontStyle={fontStyleItalic} pt={paddingTop}>
      "Discover how our meticulous light rigging plans and detailed visualizations bring your event's vision to life."
      </Text>,
      <Image src={light5} borderRadius={borderRadius} maxW={maxWValue}/>],
      <div>
      <Heading textAlign={textAlignCenter} size={headingSize}>
      Precision Planning in Light Artistry
      </Heading>
      <Text textAlign={textAlignLeft} mb={0} fontSize={fontSize}>
      At Nordiv AB, each lighting project begins with detailed planning—from rigorous rigging diagrams to isometric stage views and full visualizations. This comprehensive preparation ensures that every lighting element is precisely pre-programed long before execution. The progression from detailed plans to dynamic lighting setups guarantees that each event we illuminate is not only seamless but also spectacular.
      </Text>
     </div>
  ];
  return (
    <Box
      bg="#fafffe"
      d="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      position="relative"
    >
      <Box position="relative" minW={'100vw'} h={'100vh'}>
        <Skeleton isLoaded={imgLoaded}>
          <Image
            src={lightOpening}
            alt="Descriptive alt text"
            position={'absolute'}
            minW={'100vw'}
            h={'100vh'}
            objectFit={'cover'}
            onLoad={() => setImgLoaded(true)}
          />
        </Skeleton>
        <Heading
          size={'xl'}
          left={leftTitlePosition}
          bottom={bottomTitlePosition}
          color={'white'} 
          position={'absolute'} 
          whiteSpace={wrap}
          textShadow={textBorder}
          fontFamily={'Deserta'}
        >
          Light Artistry
        </Heading>
      </Box>
      <Box zIndex={1} tex>
        <Section
          title={"Light Artistry"}
          content={collection}
        />
      </Box>
    </Box>
  );
}

export default LightArtistry;
