import React from 'react';
import { Box, Heading, VStack, Text, Image, useBreakpointValue, Flex, Center } from '@chakra-ui/react';
import { useInView } from 'react-intersection-observer';

function FadeInBox({ children }) {
  const { ref, inView } = useInView({
    triggerOnce: true, // Change to false if you want it to trigger every time it comes into view
    threshold: 0.2, // 10% of the item must be visible for the animation to start
  });

  return (
    <Box
      ref={ref}
      opacity={inView ? 1 : 0}
      transform={inView ? 'translateY(0px)' : 'translateY(20px)'}
      transition="opacity 0.5s ease-out, transform 0.5s ease-out"
    >
      {children}
    </Box>
  );
}

function Section({ title, subtitle, content }) {
  const breakpoint = useBreakpointValue({ base: 'base', md: 'md', lg: 'lg' });

  return (
      <Box d="flex" flexDirection="column" alignItems="center" justifyContent="center" pl={"10vw"} pr={"10vw"}
      mt={-4}>
        {content.map((item, index) => {
          const isLastItem = index === content.length - 1;
          const marginBottom = isLastItem ? 0 : 50;
          const padding = isLastItem ? 10 : 0;
          if (Array.isArray(item)) {
            const isImageFirst = item[0].type === Image || item[0].type === 'video';
            const text = isImageFirst ? item[1] : item[0];
            const image = isImageFirst ? item[0] : item[1];

            return (
              <FadeInBox key={index}>
                <Center mt={100} mb={0} pb={padding}>
                  <Flex direction={breakpoint === 'base' ? 'column' : isImageFirst ? 'row' : 'row-reverse'} alignItems="center">
                    {React.cloneElement(image, { mb: breakpoint === 'base' ? 4 : 0 })}
                    <Box ml={100} mr={100}>
                      <Text>{text}</Text>
                    </Box>
                  </Flex>
                </Center>
              </FadeInBox>
            );
          } else {
            return (
              <FadeInBox key={index}>
                <Center mt={50} mb={marginBottom} pb={padding}>
                  {item}
                </Center>
              </FadeInBox>
            );
          }
        })}
      </Box>
  );
}

export default Section;
