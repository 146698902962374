import { Box, Center, Grid, Link, useBreakpointValue } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import light from '../includes/images/light/TitleCard_LightArtestry_1080.webp';
import graphic from '../includes/images/graphic/TitleCard_Graphic_1080.webp';
import spaces from '../includes/images/spaces/TitleCard_SustainableSpaces_1080.webp';

const CardLinks = () => {
  const minHeight = useBreakpointValue({base: 200, lg: 400});
  const marginTop = useBreakpointValue({base: 200, lg: 400});

  return (
    <Grid bg={'#F7F2F8'} templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }} gap={4} fontFamily={'Deserta'} m={0}>
      <Link as={RouterLink} to="/light" _hover={{textDecoration: 'none'}}>
        <Box
          bg="gray.200"
          borderRadius="lg"
          p={4}
          transition="all 0.3s"
          _hover={{ transform: 'scale(1.05)' }}
          backgroundImage={`url(${light})`}
          backgroundSize="cover"
          backgroundPosition="center center"
          backgroundRepeat={'no-repeat'}
          minH={minHeight}
        >
          <Center mt={marginTop} color={"#e84f1c"}>Light Artistry</Center>
        </Box>
      </Link>
      <Link as={RouterLink} to="/graphic" _hover={{textDecoration: 'none'}}>
        <Box
          bg="gray.200"
          borderRadius="lg"
          p={4}
          transition="all 0.3s"
          _hover={{ transform: 'scale(1.05)' }}
          backgroundImage={`url(${graphic})`}
          backgroundSize="cover"
          backgroundPosition="center center"
          backgroundRepeat={'no-repeat'}
          minH={minHeight}
        >
          <Center mt={marginTop} color={'#F9F9F9'}>Graphic Design</Center>
        </Box>
      </Link>
      <Link as={RouterLink} to="/spaces" _hover={{textDecoration: 'none'}}>
        <Box
          bg="gray.200"
          borderRadius="lg"
          p={4}
          transition="all 0.3s"
          _hover={{ transform: 'scale(1.05)' }}
          backgroundImage={`url(${spaces})`}
          backgroundSize="cover"
          backgroundPosition="center center"
          backgroundRepeat={'no-repeat'}
          minH={minHeight}
        >
          <Center mt={marginTop} color={"#e84f1c"}>Sustainable Spaces</Center>
        </Box>
      </Link>
    </Grid>
  );
};

export default CardLinks;