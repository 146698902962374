import { Box, Container, Divider, Stack, useBreakpointValue, Link, Image, VisuallyHidden, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import nordivlogo from '../includes/icons/nivlogo.webp'
import insta from '../includes/icons/InstagramIconClean.webp'
import phone from '../includes/icons/PhoneIconClean.webp'
import youtube from '../includes/icons/YoutubeIconClean.webp'

const facts = [
  '"Did you know Nordiv specializes in environmentally conscious lighting and stage set designs?"',
  '"Did you know Nordiv harnesses a broad network of specialists across various fields to bring your projects to life?"',
  '"Did you know The name Nordiv stands for Nord Interactive Visuals, symbolizing our commitment to creative and interactive solutions.?"',
  '"Did you know Nordiv has uniquely designed, manufactured, and installed tailor-made solutions for our clients that are not found elsewhere?"',
  '"Did you know Nordiv has crafted special products to meet the unique demands of certain clients?"',
  '"Did you know Nordiv offers assistance in producing graphical sales materials and provides support for your crucial pitch manuscripts?"',
  '"Did you know achieving peak performance requires balance, and Nordiv questions if you\'ve found your way to center yourself?"',
  '"Did you know specific environments can significantly enhance your creativity, according to Nordiv\'s philosophy?"',
  '"Did you know The Nordiv logo is inspired by Nordic runes, representing creativity, wealth, and prosperity?"',
  '"Did you know Nordiv\'s headquarters is located in a historical area of Sweden, known as a bastion of Viking heritage?"',
  '"Did you know Nordiv suggests using certain peripherals to boost creativity in online meetings?"',
  '"Did you know Nordiv can provide comprehensive services for any lighting installation needs, whether indoors or outdoors?"',
  '"Did you know The Nordiv community embraces the term “Tårta,” equating it to the concept of “Awesome”?',
  '"Did you know a project\'s success is often tied to having a unified vision, a principle Nordiv stands by?"',
  '"Did you know Nordiv believes lighting design can play a key role in influencing audience emotions?"',
  '"Did you know The founder of Nordiv was instrumental in creating the “World and Beyond” visualization room at Universeum in Gothenburg?"',
  '"Did you know Nordivs founder designed and manufactured “The Box,” a notable set piece for the opera world tour: The Rake Project 2018-2019?"',
  '"Did you know Nordiv has developed soundproof boxes with ventilation for particularly challenging technical installations?"',
  '"Did you know Nordiv excels in creating digital media for multi-screen display solutions, enhancing viewer experiences?"',
  '"Did you know Nordiv utilizes Unreal Engine for a variety of tasks across numerous projects, leveraging cutting-edge technology?"',
  '"Did you know that AI tools are pivotal in Nordiv’s arsenal, applied across many different areas of our work?"',
  '"Did you know that there are centuries-old methods of making sustainable wooden panels that will last for centuries with minimal maintenance and costs?"',
  '"Did you know that Nordiv Sustainable Spaces focuses on designing smaller, more effective, lower-cost, and long-lasting homes tailored to the intended homeowner?"',
  '"Did you know that pure water is becoming increasingly scarce on our planet? Consequently, all Sustainable Spaces are designed to minimize water consumption?"',
  '"Did you know that it is possible to pre-program much of a show before on site rigging?"',
  '"Did you know that proper planning is key to staying within budget while securing a good result at the same time?"',
  '"Did you know that lighting and sound alone can tell powerful stories?"',
  '"Did you know that Nordivs areas of expertise make it exceptionally proficient in designing public spaces like museums, lobbies, and offices?"'
];

const getRandomFact = () => {
  const randomIndex = Math.floor(Math.random() * facts.length);
  return facts[randomIndex];
};

const Footer = () => {
  const location = useLocation();
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const [randomFact, setRandomFact] = useState('');
  const maxIconW = useBreakpointValue({base: '30px', lg: '40px'})

  useEffect(() => {
    setRandomFact(getRandomFact());
  }, [location]);

  return (
    <Box as="footer" bg="#111112" color="#e94e1b" minH="20vh" p={4}>
      <Container centerContent py={4} m={'auto'}>
        <Stack direction={isMobile ? "column" : "column"} spacing={4} alignItems="center">
        <Box>
          <Image src={nordivlogo} alt="Footer Icon" maxW={'52px'} />
          </Box>
          <Box>
            <Stack direction={isMobile ? "row" : "row"} spacing={4}>
                <Link href="tel:+1234567890" aria-label="Phone">
                  <VisuallyHidden>Phone</VisuallyHidden>
                  <Image src={phone} alt="Phone" maxW={maxIconW}/>
                </Link>
                <Link href="https://www.instagram.com/nord.iv/" isExternal aria-label="Instagram">
                  <VisuallyHidden>Instagram</VisuallyHidden>
                  <Image src={insta} alt="Instagram" maxW={maxIconW}/>
                </Link>
                <Link href="https://www.youtube.com/@NordivAB" isExternal aria-label="YouTube">
                  <VisuallyHidden>YouTube</VisuallyHidden>
                  <Image src={youtube} alt="YouTube"maxW={maxIconW}/>
                </Link>
            </Stack>
          </Box>
          <Box bg="#1a1a1e" p={2} borderRadius="md" maxW="80%">
            <Text fontSize="xs" color="honeydew">
              {randomFact}
            </Text>
          </Box>
        </Stack>
        <Text fontSize="xs" color="honeydew" textAlign="center" mt={10}>
          <span role="img" aria-label="copyright">©️</span> {new Date().getFullYear()} Nordiv, All rights reserved.
        </Text>
      </Container>
    </Box>
  );
};

export default Footer;
