import { useState, useEffect } from 'react';
import { Box } from '@chakra-ui/react';

function FadeOutBox({ children, duration }) {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, duration);

    return () => clearTimeout(timer); // Clean up on unmount
  }, [duration]);

  return (
    <Box
      opacity={isVisible ? 1 : 0}
      transition="opacity 0.5s ease-out"
    >
      {children}
    </Box>
  );
}

export default FadeOutBox;